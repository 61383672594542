import { stealAllPropertyRecord } from "../../components/squads-page/steal-all-page";
import { Entity } from "../entities/entity";
import { BowlerSpeciality } from "../enums/bowler-speciality";
import { ExtrasType } from "../enums/extras-type";
import { MatchType } from "../enums/match-type";
import { cloneObject } from "../util-functions";
import { UUID } from "../uuid";

import { PercentDistributionBiasData } from "./ground-stats";

export interface PlayerStats {
  defensiveStrikeRate: number;
  halfInStrikeRate: number;
  inStrikeRate: number;
  maxStrikeRate: number;
  ballsUntilHalfIn: number;
  ballsUntilFullyIn: number;
  defensiveWicketPercent: number;
  inWicketPercent: number;
  maxWicketPercent: number;
  strikeRateWicketMultiplier: number;
  extremePaceStrikeRateBias: number;
  normalPaceStrikeRateBias: number;
  wristSpinStrikeRateBias: number;
  intoSpinStrikeRateBias: number;
  awaySpinStrikeRateBias: number;
  extremePaceWicketBias: number;
  normalPaceWicketBias: number;
  wristSpinWicketBias: number;
  intoSpinWicketBias: number;
  awaySpinWicketBias: number;
  extremePaceConfidence: number;
  normalPaceConfidence: number;
  wristSpinConfidence: number;
  intoSpinConfidence: number;
  awaySpinConfidence: number;
  pushAdjustment: number;
  pushAdjustmentUpToBall: number;
  batsmanGlobalPercentDistributionBiasData: PercentDistributionBiasData;
  batsmanPacePercentDistributionBiasData: PercentDistributionBiasData;
  batsmanSpinPercentDistributionBiasData: PercentDistributionBiasData;
  batsmanPercentDistributionBiasDataByPush: PercentDistributionBiasData[];

  actualWicketMultiplier: number;
  widesMultiplier: number;
  averagePosition: number;
  battingGlobalStrikeRateBias: number;
  battingGlobalWicketBias: number;
  battingGlobalConfidence: number;
  battingExperience: number;
  strikeRateBiasByPush: number[];
  wicketBiasByPush: number[];
  confidenceByPush: number[];
  aheadLimit: number;
  behindLimit: number;
  powerplayPushAdjustment: number;
  powerplayWicketBias: number;
  upToBalls: number[];
  upToBallsStrikeRateBias: number[];
  upToBallsWicketBias: number[];
  upToBallsConfidence: number[];
  bowlerGlobalPercentDistributionBiasData: PercentDistributionBiasData;
  bowlerLeftHandersPercentDistributionBiasData: PercentDistributionBiasData;
  bowlerRightHandersPercentDistributionBiasData: PercentDistributionBiasData;
  bowlerPercentDistributionBiasDataByPush: PercentDistributionBiasData[];

  spinPower: number;
  swingPower: number;
  bowlingGlobalStrikeRateBias: number;
  bowlingGlobalWicketBias: number;
  bowlingGlobalExtrasBias: number;
  bowlingGlobalConfidence: number;
  bowlingExperience: number;
  bowlingAgainstLeftHandedStrikeRateBias: number;
  bowlingAgainstLeftHandedWicketBias: number;
  bowlingAgainstLeftHandedConfidence: number;
  bowlingAgainstRightHandedStrikeRateBias: number;
  bowlingAgainstRightHandedWicketBias: number;
  bowlingAgainstRightHandedConfidence: number;
  bowlingStrikeRateBias: number[];
  bowlingWicketBias: number[];
  bowlingExtrasBias: number[];
  bowlingConfidence: number[];
  bowlingCallups: number[];

  bowlingAverageGroundSRBias: number;
  bowlingAverageGroundWicketBias: number;
  bowlingAverageOpponentSRBias: number;
  bowlingAverageOpponentWicketBias: number;
  battingAverageGroundSRBias: number;
  battingAverageGroundWicketBias: number;
  battingAverageOpponentSRBias: number;
  battingAverageOpponentWicketBias: number;

  bowlerSpecialityPercents: Map<BowlerSpeciality, number>;
  bowlerExtrasBiasesByType: Map<ExtrasType, number>;
  desiredBalls: number;
  bowler: boolean;
}

const DEFAULT_PLAYER_STATS: PlayerStats = {
  defensiveStrikeRate: 0,
  halfInStrikeRate: 0,
  inStrikeRate: 0,
  maxStrikeRate: 0,
  ballsUntilHalfIn: 0,
  ballsUntilFullyIn: 0,
  defensiveWicketPercent: 0,
  inWicketPercent: 0,
  maxWicketPercent: 0,
  strikeRateWicketMultiplier: 0,
  extremePaceStrikeRateBias: 1,
  normalPaceStrikeRateBias: 1,
  wristSpinStrikeRateBias: 1,
  intoSpinStrikeRateBias: 1,
  awaySpinStrikeRateBias: 1,
  extremePaceWicketBias: 1,
  normalPaceWicketBias: 1,
  wristSpinWicketBias: 1,
  intoSpinWicketBias: 1,
  awaySpinWicketBias: 1,
  extremePaceConfidence: 0,
  normalPaceConfidence: 0,
  wristSpinConfidence: 0,
  intoSpinConfidence: 0,
  awaySpinConfidence: 0,
  pushAdjustment: 0,
  pushAdjustmentUpToBall: 0,
  powerplayPushAdjustment: 0,
  powerplayWicketBias: 1,

  batsmanGlobalPercentDistributionBiasData: {
    boundaryToRunsBias: 1,
    fourToSixBias: 1,
    oneToTwoBias: 1,
    oneAndTwoToThreeBias: 1,
  },
  batsmanPacePercentDistributionBiasData: {
    boundaryToRunsBias: 1,
    fourToSixBias: 1,
    oneToTwoBias: 1,
    oneAndTwoToThreeBias: 1,
  },
  batsmanSpinPercentDistributionBiasData: {
    boundaryToRunsBias: 1,
    fourToSixBias: 1,
    oneToTwoBias: 1,
    oneAndTwoToThreeBias: 1,
  },
  batsmanPercentDistributionBiasDataByPush: [
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
  ],

  bowlerGlobalPercentDistributionBiasData: {
    boundaryToRunsBias: 1,
    fourToSixBias: 1,
    oneToTwoBias: 1,
    oneAndTwoToThreeBias: 1,
  },
  bowlerLeftHandersPercentDistributionBiasData: {
    boundaryToRunsBias: 1,
    fourToSixBias: 1,
    oneToTwoBias: 1,
    oneAndTwoToThreeBias: 1,
  },
  bowlerRightHandersPercentDistributionBiasData: {
    boundaryToRunsBias: 1,
    fourToSixBias: 1,
    oneToTwoBias: 1,
    oneAndTwoToThreeBias: 1,
  },
  bowlerPercentDistributionBiasDataByPush: [
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
    {
      boundaryToRunsBias: 1,
      fourToSixBias: 1,
      oneToTwoBias: 1,
      oneAndTwoToThreeBias: 1,
    },
  ],

  actualWicketMultiplier: 2,
  widesMultiplier: 1,
  averagePosition: 0,
  battingGlobalStrikeRateBias: 1,
  battingGlobalWicketBias: 1,
  battingGlobalConfidence: 0,
  battingExperience: 0,
  strikeRateBiasByPush: [1, 1, 1, 1, 1],
  wicketBiasByPush: [1, 1, 1, 1, 1],
  confidenceByPush: [0, 0, 0, 0, 0],
  aheadLimit: 10,
  behindLimit: 10,

  upToBalls: [10, 20],
  upToBallsStrikeRateBias: [1, 1, 1],
  upToBallsWicketBias: [1, 1, 1],
  upToBallsConfidence: [0, 0, 0],

  spinPower: 0,
  swingPower: 0,
  bowlingGlobalStrikeRateBias: 1,
  bowlingGlobalWicketBias: 1,
  bowlingGlobalExtrasBias: 1,
  bowlingGlobalConfidence: 0,
  bowlingExperience: 0,
  bowlingAgainstLeftHandedStrikeRateBias: 1,
  bowlingAgainstLeftHandedWicketBias: 1,
  bowlingAgainstLeftHandedConfidence: 0,
  bowlingAgainstRightHandedStrikeRateBias: 1,
  bowlingAgainstRightHandedWicketBias: 1,
  bowlingAgainstRightHandedConfidence: 0,
  bowlingStrikeRateBias: [1, 1, 1, 1, 1],
  bowlingWicketBias: [1, 1, 1, 1, 1],
  bowlingExtrasBias: [1, 1, 1, 1, 1],
  bowlingConfidence: [0, 0, 0, 0, 0],
  bowlingCallups: [0, 0, 0, 0, 0],

  bowlingAverageGroundSRBias: 1,
  bowlingAverageGroundWicketBias: 1,
  bowlingAverageOpponentSRBias: 1,
  bowlingAverageOpponentWicketBias: 1,
  battingAverageGroundSRBias: 1,
  battingAverageGroundWicketBias: 1,
  battingAverageOpponentSRBias: 1,
  battingAverageOpponentWicketBias: 1,

  desiredBalls: 3,
  bowlerSpecialityPercents: buildDefaultPhaseMap(1),
  bowlerExtrasBiasesByType: buildDefaultExtrasBiasesByType(),
  bowler: true,
};

export function buildDefaultPhaseMap(
  defaultValue: number
): Map<BowlerSpeciality, number> {
  const bowlerSpecialityPercents: Map<BowlerSpeciality, number> = new Map();
  Object.keys(BowlerSpeciality).forEach((speciality) => {
    bowlerSpecialityPercents.set(BowlerSpeciality[speciality], defaultValue);
  });
  return bowlerSpecialityPercents;
}

export function buildDefaultExtrasBiasesByType(): Map<ExtrasType, number> {
  const map: Map<ExtrasType, number> = new Map();
  Object.keys(ExtrasType).forEach((type) => {
    map.set(ExtrasType[type], 1);
  });
  return map;
}

export function serializeMatchPlayerStats(matchPlayerStats: MatchPlayerStats) {
  return {
    playerStatsId:
      (!!matchPlayerStats.playerStatsId &&
        matchPlayerStats.playerStatsId.value) ||
      null,
    playerId:
      (!!matchPlayerStats.playerId && matchPlayerStats.playerId.value) || null,
    matchId:
      (!!matchPlayerStats.matchId && matchPlayerStats.matchId.value) || null,
    matchPlayerStatsId:
      (!!matchPlayerStats.matchPlayerStatsId &&
        matchPlayerStats.matchPlayerStatsId.value) ||
      null,
    createdBy:
      (!!matchPlayerStats.createdBy && matchPlayerStats.createdBy.value) ||
      null,
  };
}

export interface MatchPlayerStats {
  playerStatsId: UUID;
  playerId: UUID;
  matchId: UUID;
  matchPlayerStatsId: UUID;
  createdBy: UUID;
}

export class PlayerStatsWrapper implements Entity {
  public playerStatsId: UUID;
  public createdBy: UUID;
  public createdAt: number;
  public name: string;
  public playerStats: PlayerStats;
  public matchType: MatchType;

  constructor(
    playerStatsId: UUID,
    createdBy: UUID,
    createdAt: number,
    name: string,
    playerStats: PlayerStats,
    matchType: MatchType
  ) {
    this.playerStatsId = playerStatsId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.name = name;
    this.playerStats = playerStats;
    this.matchType = matchType;
  }

  public static getAllProperties(...StealAllPage): string[] {
    return Object.keys(stealAllPropertyRecord).filter(
      (property) =>
        StealAllPage.find(
          (page) => page === stealAllPropertyRecord[property]
        ) !== undefined
    );
  }

  public static clone(initialStats: PlayerStats): PlayerStats {
    return this.deserializePlayerStats(
      cloneObject(this.serializePlayerStats(initialStats))
    );
  }

  public static serialize(playerStatsWrapper: PlayerStatsWrapper) {
    return {
      playerStatsId: !!playerStatsWrapper.playerStatsId
        ? playerStatsWrapper.playerStatsId.value
        : null,
      createdBy: !!playerStatsWrapper.createdBy
        ? playerStatsWrapper.createdBy.value
        : null,
      createdAt: playerStatsWrapper.createdAt,
      name: playerStatsWrapper.name,
      matchType: playerStatsWrapper.matchType,
      playerStats: this.serializePlayerStats(playerStatsWrapper.playerStats),
    };
  }

  public static serializePlayerStats(playerStats: PlayerStats) {
    return {
      ...playerStats,
      bowlerSpecialityPercents: this.serializeMap(
        playerStats.bowlerSpecialityPercents
      ),
      bowlerExtrasBiasesByType: this.serializeMap(
        playerStats.bowlerExtrasBiasesByType
      ),
    };
  }

  public static clonePlayerStatsWrapperMap(
    playerStats: Map<string, PlayerStatsWrapper>
  ): Map<string, PlayerStatsWrapper> {
    const clonedMap = new Map<string, PlayerStatsWrapper>();
    playerStats.forEach(
      (playerStatsWrapper: PlayerStatsWrapper, playerId: string) => {
        clonedMap.set(
          playerId,
          PlayerStatsWrapper.clonePlayerStatsWrapper(playerStatsWrapper)
        );
      }
    );
    return clonedMap;
  }

  public static clonePlayerStatsWrapper(
    playerStatsWrapper: PlayerStatsWrapper
  ): PlayerStatsWrapper {
    return PlayerStatsWrapper.deserializeOne(
      JSON.parse(
        JSON.stringify(PlayerStatsWrapper.serialize(playerStatsWrapper))
      )
    );
  }

  public static serializePlayerStatsMap(
    map: Map<string, PlayerStatsWrapper>
  ): any {
    const result = {};
    map.forEach((playerStatsWrapper, playerId) => {
      result[playerId] = PlayerStatsWrapper.serialize(playerStatsWrapper);
    });

    return result;
  }

  private static serializeMap(map: Map<any, any>): any {
    const result = {};
    map.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }

  public static deserializeMap(json: any): Map<string, PlayerStatsWrapper> {
    const map: Map<string, PlayerStatsWrapper> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(key, this.deserializeOne(value));
    }
    return map;
  }

  public static deserializeOne(responseJSON: any): PlayerStatsWrapper {
    return new PlayerStatsWrapper(
      UUID.fromString(responseJSON.playerStatsId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      responseJSON.name,
      this.deserializePlayerStats(responseJSON.playerStats),
      MatchType[responseJSON.matchType]
    );
  }

  public static deserializePlayerStats(json: any): PlayerStats {
    return {
      ...json,
      bowlerSpecialityPercents: this.deserializeBowlerSpecialityPercentsMap(
        json.bowlerSpecialityPercents
      ),
      bowlerExtrasBiasesByType: this.deserializeExtrasTypeMap(
        json.bowlerExtrasBiasesByType
      ),
    };
  }

  public static deserializeBowlerSpecialityPercentsMap(
    json: any
  ): Map<BowlerSpeciality, number> {
    const map: Map<BowlerSpeciality, number> = new Map();
    Object.keys(json).forEach((key) => {
      map.set(BowlerSpeciality[key], Number(json[key]));
    });
    return map;
  }

  public static deserializeExtrasTypeMap(json: any): Map<ExtrasType, number> {
    const map: Map<ExtrasType, number> = new Map();
    Object.keys(json).forEach((key) => {
      map.set(ExtrasType[key], Number(json[key]));
    });
    return map;
  }

  public static deserializeList(json: any): PlayerStatsWrapper[] {
    const stats: PlayerStatsWrapper[] = [];
    json.forEach((element) => {
      stats.push(this.deserializeOne(element));
    });
    return stats;
  }

  public static emptyWithName(name: string): PlayerStatsWrapper {
    return new PlayerStatsWrapper(
      null,
      null,
      null,
      name,
      DEFAULT_PLAYER_STATS,
      MatchType.T20_AND_HUNDRED
    );
  }

  public static getTypeName(): string {
    return "stats";
  }

  public toString(): string {
    return this.name;
  }

  public static buildDefaultBowlerSpecialityPercentsMap(): Map<
    BowlerSpeciality,
    number
  > {
    const map: Map<BowlerSpeciality, number> = new Map();
    map.set(BowlerSpeciality.DEATH, 0.2);
    map.set(BowlerSpeciality.EARLY_MIDDLE, 0.2);
    map.set(BowlerSpeciality.FIRST_CHANGE, 0.2);
    map.set(BowlerSpeciality.SWING, 0.2);
    map.set(BowlerSpeciality.LATE_MIDDLE, 0.2);
    return map;
  }

  public static buildDefaultBowlerExtrasTypeMap(): Map<ExtrasType, number> {
    const map: Map<ExtrasType, number> = new Map();
    map.set(ExtrasType.BYE, 1);
    map.set(ExtrasType.LEG_BYE, 1);
    map.set(ExtrasType.WIDE, 1);
    map.set(ExtrasType.NO_BALL, 1);
    return map;
  }

  public static buildDefaultPlayerStatsWrapper(
    playerStatsId: string,
    order: number,
    matchType: MatchType,
    defensiveStrikeRate: number,
    halfInStrikeRate: number,
    inStrikeRate: number,
    maxStrikeRate: number,
    defensiveWicketPercent: number,
    inWicketPercent: number,
    maxWicketPercent: number,
    ballsUntilHalfIn: number,
    ballsUntilFullyIn: number,
    strikeRateWicketMultiplier: number
  ): PlayerStatsWrapper {
    return new PlayerStatsWrapper(
      UUID.fromString(playerStatsId),
      null,
      0,
      "Default Player " + order,
      {
        inStrikeRate,
        maxStrikeRate,
        inWicketPercent,
        ballsUntilHalfIn,
        halfInStrikeRate,
        maxWicketPercent,
        ballsUntilFullyIn,
        defensiveStrikeRate,
        defensiveWicketPercent,
        strikeRateWicketMultiplier,
        bowler: true,
        spinPower: 0,
        upToBalls: [10, 20],
        aheadLimit: 10,
        swingPower: 0,
        behindLimit: 10,
        desiredBalls: 3,
        bowlingCallups: [0, 0, 0, 0, 0, 0],
        pushAdjustment: 0,
        averagePosition: 0,
        widesMultiplier: 1,
        confidenceByPush: [0, 0, 0, 0, 0],
        wicketBiasByPush: [1, 1, 1, 1, 1],
        bowlingConfidence: [0, 0, 0, 0, 0, 0],
        bowlingWicketBias: [1, 1, 1, 1, 1, 1],
        awaySpinConfidence: 0,
        awaySpinWicketBias: 1,
        intoSpinConfidence: 0,
        intoSpinWicketBias: 1,
        upToBallsConfidence: [0, 0, 0],
        upToBallsWicketBias: [1, 1, 1],
        wristSpinConfidence: 0,
        wristSpinWicketBias: 1,
        normalPaceConfidence: 0,
        normalPaceWicketBias: 1,
        strikeRateBiasByPush: [1, 1, 1, 1, 1],
        bowlingStrikeRateBias: [1, 1, 1, 1, 1, 1],
        bowlingExtrasBias: [1, 1, 1, 1, 1, 1],
        extremePaceConfidence: 0,
        extremePaceWicketBias: 1,
        actualWicketMultiplier: 2,
        awaySpinStrikeRateBias: 1,
        intoSpinStrikeRateBias: 1,
        pushAdjustmentUpToBall: 0,
        powerplayPushAdjustment: 0,
        powerplayWicketBias: 1,

        batsmanGlobalPercentDistributionBiasData: {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        batsmanPacePercentDistributionBiasData: {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        batsmanSpinPercentDistributionBiasData: {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        batsmanPercentDistributionBiasDataByPush: [
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
        ],

        bowlerGlobalPercentDistributionBiasData: {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        bowlerLeftHandersPercentDistributionBiasData: {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        bowlerRightHandersPercentDistributionBiasData: {
          boundaryToRunsBias: 1,
          fourToSixBias: 1,
          oneToTwoBias: 1,
          oneAndTwoToThreeBias: 1,
        },
        bowlerPercentDistributionBiasDataByPush: [
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
          {
            boundaryToRunsBias: 1,
            fourToSixBias: 1,
            oneToTwoBias: 1,
            oneAndTwoToThreeBias: 1,
          },
        ],

        battingGlobalConfidence: 0,
        battingGlobalWicketBias: 1,
        bowlingGlobalConfidence: 0,
        bowlingGlobalWicketBias: 1,
        upToBallsStrikeRateBias: [1, 1, 1],
        wristSpinStrikeRateBias: 1,
        bowlerSpecialityPercents:
          PlayerStatsWrapper.buildDefaultBowlerSpecialityPercentsMap(),
        bowlerExtrasBiasesByType:
          PlayerStatsWrapper.buildDefaultBowlerExtrasTypeMap(),
        normalPaceStrikeRateBias: 1,
        extremePaceStrikeRateBias: 1,
        battingAverageGroundSRBias: 1,
        bowlingAverageGroundSRBias: 1,
        battingGlobalStrikeRateBias: 1,
        bowlingGlobalStrikeRateBias: 1,
        bowlingGlobalExtrasBias: 1,
        battingAverageOpponentSRBias: 1,
        bowlingAverageOpponentSRBias: 1,
        battingAverageGroundWicketBias: 1,
        bowlingAverageGroundWicketBias: 1,
        battingAverageOpponentWicketBias: 1,
        bowlingAverageOpponentWicketBias: 1,
        bowlingAgainstLeftHandedConfidence: 0,
        bowlingAgainstLeftHandedWicketBias: 1,
        bowlingAgainstRightHandedConfidence: 0,
        bowlingAgainstRightHandedWicketBias: 1,
        bowlingAgainstLeftHandedStrikeRateBias: 1,
        bowlingAgainstRightHandedStrikeRateBias: 1,
        battingExperience: 1,
        bowlingExperience: 1,
      },
      matchType
    );
  }

  public static buildDefaultStatsForT20(): Map<number, PlayerStatsWrapper> {
    const map: Map<number, PlayerStatsWrapper> = new Map();

    // ABSOLUTE DEFAULTS FOR T20
    map.set(
      0,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "7864dc05-61f7-4c70-bdfc-e6885e7db9b2",
        1,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      1,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "15a22106-9144-4cf9-9dcb-60a832c02420",
        2,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      2,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "01b8ccfc-3716-40fb-b5e0-a6bfaf542d5e",
        3,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      3,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "1ea885a2-1e05-40a2-ac13-d4f3e8b793a6",
        4,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      4,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "9cfb770c-075d-4922-b1c7-02e058a38753",
        5,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.095009834,
        3,
        15,
        46
      )
    );
    map.set(
      5,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "94f11367-02fb-4a0f-8af5-de1128dccbf3",
        6,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.099303527,
        3,
        15,
        45
      )
    );
    map.set(
      6,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b8ae7a2d-27f5-48ed-b5a1-8d797014f78c",
        7,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.33,
        0.45,
        1.72,
        0.015,
        0.014,
        0.103516617,
        3,
        15,
        44
      )
    );
    map.set(
      7,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "1d5e3f40-51d8-4da2-9ec9-ec648e08f506",
        8,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.31,
        0.4,
        1.6,
        0.018,
        0.015,
        0.10868684,
        3,
        15,
        42
      )
    );
    map.set(
      8,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b3b1aca2-63d2-45ca-a8d8-f7896880f4f0",
        9,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.3,
        0.35,
        1.48,
        0.023,
        0.017,
        0.120464383,
        3,
        12,
        40
      )
    );
    map.set(
      9,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "12e06674-f67f-4489-816a-6e8e707703bd",
        10,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.25,
        0.3,
        1.35,
        0.03,
        0.021,
        0.168,
        3,
        9,
        35
      )
    );
    map.set(
      10,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "a8631948-c5ff-4dfd-8bec-da65c6d716c9",
        11,
        MatchType.T20_AND_HUNDRED,
        0.2,
        0.22,
        0.25,
        1.2,
        0.04,
        0.025,
        0.22449241,
        3,
        7,
        30
      )
    );
    return map;
  }

  public static buildDefaultStatsForODI(): Map<number, PlayerStatsWrapper> {
    const map: Map<number, PlayerStatsWrapper> = new Map();
    // ABSOLUTE DEFAULTS FOR ODI
    map.set(
      0,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "7864dc05-61f7-4c70-bdfc-e6885e7db9b3",
        1,
        MatchType.ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      1,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "15a22106-9144-4cf9-9dcb-60a832c02421",
        2,
        MatchType.ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      2,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "01b8ccfc-3716-40fb-b5e0-a6bfaf542d5f",
        3,
        MatchType.ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      3,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "1ea885a2-1e05-40a2-ac13-d4f3e8b793a7",
        4,
        MatchType.ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      4,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "9cfb770c-075d-4922-b1c7-02e058a38754",
        5,
        MatchType.ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.095009834,
        3,
        15,
        46
      )
    );
    map.set(
      5,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "94f11367-02fb-4a0f-8af5-de1128dccbf4",
        6,
        MatchType.ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.099303527,
        3,
        15,
        45
      )
    );
    map.set(
      6,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b8ae7a2d-27f5-48ed-b5a1-8d797014f78d",
        7,
        MatchType.ODI,
        0.2,
        0.33,
        0.45,
        1.72,
        0.015,
        0.014,
        0.103516617,
        3,
        15,
        44
      )
    );
    map.set(
      7,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "1d5e3f40-51d8-4da2-9ec9-ec648e08f507",
        8,
        MatchType.ODI,
        0.2,
        0.31,
        0.4,
        1.6,
        0.018,
        0.015,
        0.10868684,
        3,
        15,
        42
      )
    );
    map.set(
      8,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b3b1aca2-63d2-45ca-a8d8-f7896880f4f1",
        9,
        MatchType.ODI,
        0.2,
        0.3,
        0.35,
        1.48,
        0.023,
        0.017,
        0.120464383,
        3,
        12,
        40
      )
    );
    map.set(
      9,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "12e06674-f67f-4489-816a-6e8e707703be",
        10,
        MatchType.ODI,
        0.2,
        0.25,
        0.3,
        1.35,
        0.03,
        0.021,
        0.168,
        3,
        9,
        35
      )
    );
    map.set(
      10,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "a8631948-c5ff-4dfd-8bec-da65c6d716c0",
        11,
        MatchType.ODI,
        0.2,
        0.22,
        0.25,
        1.2,
        0.04,
        0.025,
        0.22449241,
        3,
        7,
        30
      )
    );
    return map;
  }

  public static buildDefaultStatsForT10(): Map<number, PlayerStatsWrapper> {
    const map: Map<number, PlayerStatsWrapper> = new Map();
    // ABSOLUTE DEFAULTS FOR T10
    map.set(
      0,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "1e53670f-c5bc-49cd-a568-2b85211cc770",
        1,
        MatchType.T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      1,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "fed10619-9cec-46f8-a82a-fc175893174e",
        2,
        MatchType.T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      2,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "98dfee01-2a86-465f-9bc6-403129fdff9b",
        3,
        MatchType.T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      3,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "aa537f84-7959-41bb-bc57-df1b39da8879",
        4,
        MatchType.T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      4,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "334f7dcf-1e00-48c2-bd19-131ec47e9332",
        5,
        MatchType.T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.095009834,
        3,
        15,
        46
      )
    );
    map.set(
      5,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "ef9316ac-c2eb-4b2c-9ea5-3c9c79907565",
        6,
        MatchType.T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.099303527,
        3,
        15,
        45
      )
    );
    map.set(
      6,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "946f4820-7833-4e18-b5d8-3ff15c7c5825",
        7,
        MatchType.T10,
        0.2,
        0.33,
        0.45,
        1.72,
        0.015,
        0.014,
        0.103516617,
        3,
        15,
        44
      )
    );
    map.set(
      7,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "47335a54-1cd1-4e78-8b61-3be18218533e",
        8,
        MatchType.T10,
        0.2,
        0.31,
        0.4,
        1.6,
        0.018,
        0.015,
        0.10868684,
        3,
        15,
        42
      )
    );
    map.set(
      8,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "69a7c8e1-0efa-4bad-8f05-f554910307ad",
        9,
        MatchType.T10,
        0.2,
        0.3,
        0.35,
        1.48,
        0.023,
        0.017,
        0.120464383,
        3,
        12,
        40
      )
    );
    map.set(
      9,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "a3da7dd7-6d81-4e39-97f4-fc77b141a6aa",
        10,
        MatchType.T10,
        0.2,
        0.25,
        0.3,
        1.35,
        0.03,
        0.021,
        0.168,
        3,
        9,
        35
      )
    );
    map.set(
      10,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "39443fd8-8527-47f6-b731-c5b05c2e7b79",
        11,
        MatchType.T10,
        0.2,
        0.22,
        0.25,
        1.2,
        0.04,
        0.025,
        0.22449241,
        3,
        7,
        30
      )
    );
    return map;
  }

  public static buildDefaultStatsForT20Women(): Map<
    number,
    PlayerStatsWrapper
  > {
    const map: Map<number, PlayerStatsWrapper> = new Map();
    // ABSOLUTE DEFAULTS FOR T20 Women
    map.set(
      0,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "d2402a23-0d62-4ced-9f3d-b3e8cef57803",
        1,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      1,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "dbb95761-e8b1-48de-9125-9c4a64fead06",
        2,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      2,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "e281b76a-f2fd-48e7-83ff-bc52a24fe176",
        3,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      3,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "cfe5ec02-7c38-4a82-adce-6abccb087138",
        4,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      4,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "a0342cca-4516-471d-ac86-52e3a7fc1c89",
        5,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.095009834,
        3,
        15,
        46
      )
    );
    map.set(
      5,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "e27d3654-7dee-4039-8454-fa919c1d3228",
        6,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.099303527,
        3,
        15,
        45
      )
    );
    map.set(
      6,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "da370b9e-ef4a-407d-8230-3299cb35a9f1",
        7,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.33,
        0.45,
        1.72,
        0.015,
        0.014,
        0.103516617,
        3,
        15,
        44
      )
    );
    map.set(
      7,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "c938e36b-9e4d-4ba0-beaa-e544a92d052f",
        8,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.31,
        0.4,
        1.6,
        0.018,
        0.015,
        0.10868684,
        3,
        15,
        42
      )
    );
    map.set(
      8,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "61ef911e-4026-44e5-82a3-851bdc28248f",
        9,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.3,
        0.35,
        1.48,
        0.023,
        0.017,
        0.120464383,
        3,
        12,
        40
      )
    );
    map.set(
      9,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "f20d499e-0eca-4189-9936-93f3bb57536b",
        10,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.25,
        0.3,
        1.35,
        0.03,
        0.021,
        0.168,
        3,
        9,
        35
      )
    );
    map.set(
      10,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "84a24868-9844-41bf-b4f5-8befce22cb58",
        11,
        MatchType.WOMENS_T20_AND_HUNDRED,
        0.2,
        0.22,
        0.25,
        1.2,
        0.04,
        0.025,
        0.22449241,
        3,
        7,
        30
      )
    );
    return map;
  }

  public static buildDefaultStatsForODIWomen(): Map<
    number,
    PlayerStatsWrapper
  > {
    const map: Map<number, PlayerStatsWrapper> = new Map();

    // ABSOLUTE DEFAULTS FOR ODI WOMEN
    map.set(
      0,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "373aa081-680d-43fc-9334-0632d501b820",
        1,
        MatchType.WOMENS_ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      1,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "33f2c294-d16a-4f7e-bc27-ec8982096c37",
        2,
        MatchType.WOMENS_ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      2,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b4c07283-5996-4d2b-ace4-245ef5260607",
        3,
        MatchType.WOMENS_ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      3,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "e73baef6-14fb-414e-ac1f-bce5ca472071",
        4,
        MatchType.WOMENS_ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      4,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "85b18247-704a-4e8d-b4b6-f747e127ac24",
        5,
        MatchType.WOMENS_ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.095009834,
        3,
        15,
        46
      )
    );
    map.set(
      5,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "6c10da03-39e6-437b-a74e-658420416fe0",
        6,
        MatchType.WOMENS_ODI,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.099303527,
        3,
        15,
        45
      )
    );
    map.set(
      6,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "f330a118-c9db-4af4-869b-29c5efb9d366",
        7,
        MatchType.WOMENS_ODI,
        0.2,
        0.33,
        0.45,
        1.72,
        0.015,
        0.014,
        0.103516617,
        3,
        15,
        44
      )
    );
    map.set(
      7,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b2de2f28-c811-44db-9b67-16967e92b52a",
        8,
        MatchType.WOMENS_ODI,
        0.2,
        0.31,
        0.4,
        1.6,
        0.018,
        0.015,
        0.10868684,
        3,
        15,
        42
      )
    );
    map.set(
      8,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "3059b21d-bae3-4447-90af-acde526149f8",
        9,
        MatchType.WOMENS_ODI,
        0.2,
        0.3,
        0.35,
        1.48,
        0.023,
        0.017,
        0.120464383,
        3,
        12,
        40
      )
    );
    map.set(
      9,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "2f3be6da-85ad-472f-a2f8-97452b2f3a86",
        10,
        MatchType.WOMENS_ODI,
        0.2,
        0.25,
        0.3,
        1.35,
        0.03,
        0.021,
        0.168,
        3,
        9,
        35
      )
    );
    map.set(
      10,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "b817e283-10b1-464d-a7d0-81df07329cd4",
        11,
        MatchType.WOMENS_ODI,
        0.2,
        0.22,
        0.25,
        1.2,
        0.04,
        0.025,
        0.22449241,
        3,
        7,
        30
      )
    );
    return map;
  }

  public static buildDefaultStatsForT10Women(): Map<
    number,
    PlayerStatsWrapper
  > {
    const map: Map<number, PlayerStatsWrapper> = new Map();

    // ABSOLUTE DEFAULTS FOR T10 WOMEN
    map.set(
      0,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "dbfcd260-0232-4a49-bcf1-779b20051993",
        1,
        MatchType.WOMENS_T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      1,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "26823a46-3dd0-4691-89f7-4635d5c88e0a",
        2,
        MatchType.WOMENS_T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      2,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "0928c183-1058-4045-876b-508f15891113",
        3,
        MatchType.WOMENS_T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      3,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "3eb2411d-0d97-4a31-8b01-486295b3639f",
        4,
        MatchType.WOMENS_T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.091072928,
        3,
        15,
        47
      )
    );
    map.set(
      4,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "9895c7c7-0eae-4192-a634-52af8269692d",
        5,
        MatchType.WOMENS_T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.095009834,
        3,
        15,
        46
      )
    );
    map.set(
      5,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "2eb6e2fe-c5e7-46d6-bc2f-5565706839bb",
        6,
        MatchType.WOMENS_T10,
        0.2,
        0.35,
        0.48,
        1.8,
        0.014,
        0.013,
        0.099303527,
        3,
        15,
        45
      )
    );
    map.set(
      6,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "f7ac6399-f6b4-419a-9a1c-1dc5976df92e",
        7,
        MatchType.WOMENS_T10,
        0.2,
        0.33,
        0.45,
        1.72,
        0.015,
        0.014,
        0.103516617,
        3,
        15,
        44
      )
    );
    map.set(
      7,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "76bf3f80-1211-40a7-8ca5-447557564042",
        8,
        MatchType.WOMENS_T10,
        0.2,
        0.31,
        0.4,
        1.6,
        0.018,
        0.015,
        0.10868684,
        3,
        15,
        42
      )
    );
    map.set(
      8,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "5e136c23-2cf5-4a9f-904a-6091c1f3c70a",
        9,
        MatchType.WOMENS_T10,
        0.2,
        0.3,
        0.35,
        1.48,
        0.023,
        0.017,
        0.120464383,
        3,
        12,
        40
      )
    );
    map.set(
      9,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "4e7e5472-a3dd-4640-821a-6f4744db52f0",
        10,
        MatchType.WOMENS_T10,
        0.2,
        0.25,
        0.3,
        1.35,
        0.03,
        0.021,
        0.168,
        3,
        9,
        35
      )
    );
    map.set(
      10,
      PlayerStatsWrapper.buildDefaultPlayerStatsWrapper(
        "9523f7bb-9fd8-4476-92e8-acdc4b76cb61",
        11,
        MatchType.WOMENS_T10,
        0.2,
        0.22,
        0.25,
        1.2,
        0.04,
        0.025,
        0.22449241,
        3,
        7,
        30
      )
    );
    return map;
  }
}
