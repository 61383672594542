import { Component } from "react";
import {
  PlayerStats,
  PlayerStatsWrapper,
} from "../../types/stats/player-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { UserPreferences } from "../../types/preferences/preferences";
import { PercentAdjustModuleType } from "../../types/simulator/modules/percent-adjust-modules";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import PlayerPercentsDistributionModalByPush from "../stats-editing-components/player-percents-distribution-modal-by-push";
import { batchUpdate } from "./squad-page-utils";

interface Props {
  index: number;
  title: string;
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  classes: string;
  userPreferences: UserPreferences;
  onArrayElementValid: (property: string, index: number, value) => void;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
}

interface State {
  percentDistributionModalOpen: boolean;
}

export class BowlingStatByPushDisplay extends Component<Props, State> {
  STATS_TO_STEAL: string[] = [
    "bowlingStrikeRateBias",
    "bowlingWicketBias",
    "bowlingExtrasBias",
    "bowlingConfidence",
    "bowlerPercentDistributionBiasDataByPush",
  ];

  constructor(props) {
    super(props);
    this.state = {
      percentDistributionModalOpen: false,
    };
  }

  onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate,
      this.props.index
    );
  };

  public render() {
    return (
      <div className="stats-modal-section">
        <div className={this.props.classes + " stats-modal-section-head"}>
          {this.props.title}
        </div>
        <div>
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Strike Rate"
            min={0}
            max={10000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.playerStats.bowlingStrikeRateBias[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.props.onArrayElementValid(
                "bowlingStrikeRateBias",
                this.props.index,
                newValue
              )
            }
            button={
              <TooltipIconButton
                title={"Percent Distribution"}
                onClick={() =>
                  this.setState({ percentDistributionModalOpen: true })
                }
                icon={"bar_chart"}
              />
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Wicket Percent"
            min={0}
            max={10000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.playerStats.bowlingWicketBias[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.props.onArrayElementValid(
                "bowlingWicketBias",
                this.props.index,
                newValue
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Extras"
            min={0}
            max={3}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.playerStats.bowlingExtrasBias[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.props.onArrayElementValid(
                "bowlingExtrasBias",
                this.props.index,
                newValue
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Confidence"
            min={0}
            max={10000}
            step={1}
            initial={
              this.props.currentStats.playerStats.bowlingConfidence[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.props.onArrayElementValid(
                "bowlingConfidence",
                this.props.index,
                newValue
              )
            }
          />
          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title-and-steal-button">
                <span>Compared to {this.props.comparedUserName}</span>
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "playerStats",
                    this.props.index
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "playerStats",
                    this.props.index
                  )}
                  onClickHandler={this.onClickHandler}
                />
              </div>
              <NumberComparison
                label="Strike Rate"
                originalValue={
                  this.props.currentStats.playerStats.bowlingStrikeRateBias[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats.bowlingStrikeRateBias[
                    this.props.index
                  ]
                }
              />
              <NumberComparison
                label="Wicket Percent"
                originalValue={
                  this.props.currentStats.playerStats.bowlingWicketBias[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats.bowlingWicketBias[
                    this.props.index
                  ]
                }
              />
              <NumberComparison
                label="Extras"
                originalValue={
                  this.props.currentStats.playerStats.bowlingExtrasBias[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats.bowlingExtrasBias[
                    this.props.index
                  ]
                }
              />
              <NumberComparison
                label="Confidence"
                originalValue={
                  this.props.currentStats.playerStats.bowlingConfidence[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats.bowlingConfidence[
                    this.props.index
                  ]
                }
              />
            </div>
          )}
        </div>

        <PlayerPercentsDistributionModalByPush
          open={this.state.percentDistributionModalOpen}
          onProceed={(stats: PlayerStats) => {
            this.setState({ percentDistributionModalOpen: false });
            this.props.onUpdate(
              new PlayerStatsWrapper(
                this.props.currentStats.playerStatsId,
                this.props.currentStats.createdBy,
                this.props.currentStats.createdAt,
                this.props.currentStats.name,
                stats,
                this.props.currentStats.matchType
              )
            );
          }}
          onCancel={() =>
            this.setState({ percentDistributionModalOpen: false })
          }
          initialStats={this.props.currentStats.playerStats}
          userPreferences={this.props.userPreferences}
          comparedStats={this.props.comparedStats?.playerStats}
          comparedUserName={this.props.comparedUserName}
          initialPushBracketIndex={this.props.index}
          globalDistributionProperty="bowlerGlobalPercentDistributionBiasData"
          globalDistributionConfidence={
            this.props.currentStats.playerStats.bowlingGlobalConfidence
          }
          percentDistributionBiasProperty="bowlerPercentDistributionBiasDataByPush"
          percentDistributionConfidenceProperty="bowlingConfidence"
          percentAdjustModuleType={PercentAdjustModuleType.BOWLER_PERCENT_BIAS}
        />
      </div>
    );
  }
}
