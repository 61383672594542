import {
  humanReadablePushBrackets,
  PushBracket,
  pushBracketClassNames,
} from "../../types/enums/push-bracket";
import { UserPreferences } from "../../types/preferences/preferences";
import { PercentAdjustModuleType } from "../../types/simulator/modules/percent-adjust-modules";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { BowlingStatByPushDisplay } from "./bowling-stat-by-push-display";
import { OpponentSkillStats } from "./opponent-skill-stats";
import { PlayerExtrasBiasesByTypeComponent } from "./player-extras-biases-by-type-component";
import { PlayerStrikeRateAndWicketPercentStats } from "./player-strike-rate-and-wicket-percent-stats";

interface Props {
  userPreferences: UserPreferences;
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  onArrayElementValid: (property: string, index: number, value) => void;
}

export default function PlayerBowlingStatsDisplay({
  userPreferences,
  currentStats,
  comparedStats,
  comparedUserName,
  onUpdate,
  onArrayElementValid,
}: Readonly<Props>): React.JSX.Element {
  const config = [
    {
      title: "Global",
      classes: "global-sr-and-wpc",
      strikeRateProperty: "bowlingGlobalStrikeRateBias",
      wicketPercentProperty: "bowlingGlobalWicketBias",
      extrasProperty: "bowlingGlobalExtrasBias",
      confidenceProperty: "bowlingGlobalConfidence",
      percentDistributionProperty: "bowlerGlobalPercentDistributionBiasData",
      percentDistributionPropertyName: "Global",
      percentDistributionConfidence:
        currentStats.playerStats.bowlingGlobalConfidence,
      globalDistributionProperty: "bowlerGlobalPercentDistributionBiasData",
      globalDistributionConfidence:
        currentStats.playerStats.bowlingGlobalConfidence,
    },
    {
      title: "Against Left Handed",
      classes: "against-left-handed",
      strikeRateProperty: "bowlingAgainstLeftHandedStrikeRateBias",
      wicketPercentProperty: "bowlingAgainstLeftHandedWicketBias",
      confidenceProperty: "bowlingAgainstLeftHandedConfidence",
      globalDistributionProperty: "bowlerGlobalPercentDistributionBiasData",
      globalDistributionConfidence:
        currentStats.playerStats.bowlingGlobalConfidence,
      percentDistributionConfidence:
        currentStats.playerStats.bowlingAgainstLeftHandedConfidence,
      percentDistributionPropertyName: "VS Left Handers",
      percentDistributionProperty:
        "bowlerLeftHandersPercentDistributionBiasData",
    },
    {
      title: "Against Right Handed",
      classes: "against-right-handed",
      strikeRateProperty: "bowlingAgainstRightHandedStrikeRateBias",
      wicketPercentProperty: "bowlingAgainstRightHandedWicketBias",
      confidenceProperty: "bowlingAgainstRightHandedConfidence",
      globalDistributionProperty: "bowlerGlobalPercentDistributionBiasData",
      globalDistributionConfidence:
        currentStats.playerStats.bowlingGlobalConfidence,
      percentDistributionConfidence:
        currentStats.playerStats.bowlingAgainstRightHandedConfidence,
      percentDistributionPropertyName: "VS Right Handers",
      percentDistributionProperty:
        "bowlerRightHandersPercentDistributionBiasData",
    },
  ];

  return (
    <div className="stats-modal-content">
      <div className="stats-modal-section">
        <div className="stats-modal-section-head">
          Multipliers at Aggression Levels (Each 20th Percentile)
        </div>
        <div className="batting-stats-modal-6-columns">
          {Object.values(PushBracket).map((pushBracket: PushBracket, index) => (
            <BowlingStatByPushDisplay
              title={humanReadablePushBrackets[pushBracket]}
              index={index}
              currentStats={currentStats}
              comparedStats={comparedStats}
              comparedUserName={comparedUserName}
              onArrayElementValid={onArrayElementValid}
              classes={pushBracketClassNames[pushBracket]}
              onUpdate={(currentStats) => onUpdate(currentStats)}
              userPreferences={userPreferences}
            />
          ))}
          <OpponentSkillStats
            currentStats={currentStats}
            comparedStats={comparedStats}
            comparedUserName={comparedUserName}
            title="Historic Averages"
            classes="global-sr-and-wpc"
            groundStrikeRateProperty="bowlingAverageGroundSRBias"
            groundWicketPercentProperty="bowlingAverageGroundWicketBias"
            opponentStrikeRateProperty="bowlingAverageOpponentSRBias"
            opponentWicketPercentProperty="bowlingAverageOpponentWicketBias"
            opponentType="Batsman"
          />
        </div>
      </div>

      <div className="bowling-stats-modal-4-columns">
        {config.map((config) => (
          <PlayerStrikeRateAndWicketPercentStats
            currentStats={currentStats}
            comparedStats={comparedStats}
            comparedUserName={comparedUserName}
            title={config.title}
            classes={config.classes}
            strikeRateProperty={config.strikeRateProperty}
            wicketPercentProperty={config.wicketPercentProperty}
            extrasProperty={config.extrasProperty}
            confidenceProperty={config.confidenceProperty}
            onUpdate={(currentStats) => onUpdate(currentStats)}
            percentDistributionProperty={config.percentDistributionProperty}
            percentDistributionPropertyName={
              config.percentDistributionPropertyName
            }
            percentDistributionConfidence={config.percentDistributionConfidence}
            globalDistributionProperty={config.globalDistributionProperty}
            globalDistributionConfidence={config.globalDistributionConfidence}
            percentAdjustModuleType={
              PercentAdjustModuleType.BOWLER_PERCENT_BIAS
            }
            userPreferences={userPreferences}
          />
        ))}

        <PlayerExtrasBiasesByTypeComponent
          currentStats={currentStats}
          comparedStats={comparedStats}
          comparedUserName={comparedUserName}
          onUpdate={(currentStats) => onUpdate(currentStats)}
        />
      </div>
    </div>
  );
}
