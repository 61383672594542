import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { BowlOutcome } from "../../types/enums/bowl-outcome";
import {
  BowlerSpeciality,
  bowlerSpecialityNames,
} from "../../types/enums/bowler-speciality";
import {
  ExtrasType,
  humanReadableExtrasType,
} from "../../types/enums/extras-type";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../types/enums/push-bracket";
import {
  GroundBiasByBowlerTypeData,
  GroundBiasByPushBracketData,
  PercentsDistributionData,
} from "../../types/form/ground-form-modules";
import {
  AveragePositionData,
  BatsmanPercentsDistributionData,
  BatsmanWidesFormData,
  BiasByBallData,
  BiasByPushBracketData,
  BowlerExtrasFormData,
  BowlerPercentsDistributionData,
  BowlerSpecialityPlayerFormData,
  BowlingAgainstBatTypeBiasData,
  BowlingBiasData,
  OpponentSkillFormData,
  StrikeRateBiasData,
  WicketBiasData,
} from "../../types/form/player-form-modules";
import { format } from "../simulator-page/simulator-utils";

export const pushBracketAndGlobalDataComponent = (
  data: GroundBiasByPushBracketData | BowlingBiasData
) => {
  const headers: string[] = [
    "Phase",
    "Actual",
    "Expected",
    "Bias",
    "Balls",
    "Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={`data-row-global`}>
          <TableCell>Global</TableCell>
          <TableCell>{format(data.globalActual)}</TableCell>
          <TableCell>{format(data.globalExpected)}</TableCell>
          <TableCell>
            {format(data.globalActual / data.globalExpected)}
          </TableCell>
          <TableCell>{format(data.globalOccurrences)}</TableCell>
          <TableCell>{format(data.globalWeight)}</TableCell>
        </TableRow>
        {Object.keys(PushBracket).map((pushBracket: string, index: number) => (
          <TableRow key={`data-row-${index}`}>
            <TableCell>
              {humanReadablePushBrackets[PushBracket[pushBracket]]}
            </TableCell>
            <TableCell>{format(data.totalActual[index])}</TableCell>
            <TableCell>{format(data.totalExpected[index])}</TableCell>
            <TableCell>
              {format(data.totalActual[index] / data.totalExpected[index])}
            </TableCell>
            <TableCell>{format(data.totalBalls[index])}</TableCell>
            <TableCell>{format(data.totalWeights[index])}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const pushBracketDataComponent = (data: BiasByPushBracketData) => {
  const headers: string[] = [
    "Phase",
    "Actual",
    "Expected",
    "Bias",
    "Balls",
    "Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(PushBracket).map((pushBracket: string, index: number) => (
          <TableRow key={`data-row-${index}`}>
            <TableCell>
              {humanReadablePushBrackets[PushBracket[pushBracket]]}
            </TableCell>
            <TableCell>{format(data.actual[index])}</TableCell>
            <TableCell>{format(data.expected[index])}</TableCell>
            <TableCell>
              {format(data.actual[index] / data.expected[index])}
            </TableCell>
            <TableCell>{format(data.occurrences[index])}</TableCell>
            <TableCell>{format(data.weight[index])}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const upToBallsDataComponent = (data: BiasByBallData) => {
  const headers: string[] = [
    "Up to Balls Bracket",
    "Actual",
    "Expected",
    "Bias",
    "Balls",
    "Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[0, 1, 2].map((index: number) => (
          <TableRow key={`data-row-${index}`}>
            <TableCell>Bracket {index + 1}</TableCell>
            <TableCell>{format(data.actual[index])}</TableCell>
            <TableCell>{format(data.expected[index])}</TableCell>
            <TableCell>
              {format(data.actual[index] / data.expected[index])}
            </TableCell>
            <TableCell>{format(data.occurrences[index])}</TableCell>
            <TableCell>{format(data.weight[index])}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const bowlerExtrasFormDataComponent = (data: BowlerExtrasFormData) => {
  const headers: string[] = ["Stat", "Actual", "Expected", "Bias"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={`data-row-global`}>
          <TableCell>Global Extras</TableCell>
          <TableCell>{format(data.totalActualExtras)}</TableCell>
          <TableCell>{format(data.totalExpectedExtras)}</TableCell>
          <TableCell>
            {format(data.totalActualExtras / data.totalExpectedExtras)}
          </TableCell>
        </TableRow>
        {Object.keys(PushBracket).map((key, index: number) => {
          const pushBracket = PushBracket[key];

          return (
            <TableRow key={`data-row-push-bracket-${index}`}>
              <TableCell>{humanReadablePushBrackets[pushBracket]}</TableCell>
              <TableCell>
                {format(data.totalActualExtrasByPush.get(pushBracket))}
              </TableCell>
              <TableCell>
                {format(data.totalExpectedExtrasByPush.get(pushBracket))}
              </TableCell>
              <TableCell>
                {format(
                  data.totalActualExtrasByPush.get(pushBracket) /
                    data.totalExpectedExtrasByPush.get(pushBracket)
                )}
              </TableCell>
            </TableRow>
          );
        })}
        {Object.keys(ExtrasType).map((key, index: number) => {
          const extrasType = ExtrasType[key];

          return (
            <TableRow key={`data-row-extras-type-${index}`}>
              <TableCell>{humanReadableExtrasType[extrasType]}s</TableCell>
              <TableCell>
                {format(data.totalActualExtrasByType.get(extrasType))}
              </TableCell>
              <TableCell>
                {format(data.totalExpectedExtrasByType.get(extrasType))}
              </TableCell>
              <TableCell>
                {format(
                  data.totalActualExtrasByType.get(extrasType) /
                    data.totalExpectedExtrasByType.get(extrasType)
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export const opponentSkillFormDataComponent = (data: OpponentSkillFormData) => {
  const headers: string[] = [
    "Total Ground SR Bias",
    "Total Ground W% Bias",
    "Total Opponent SR Bias",
    "Total Opponent W% Bias",
    "Total Weight",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{format(data.totalGroundSRBias)}</TableCell>
          <TableCell>{format(data.totalGroundWicketBias)}</TableCell>
          <TableCell>{format(data.totalOpponentSRBias)}</TableCell>
          <TableCell>{format(data.totalOpponentWicketBias)}</TableCell>
          <TableCell>{format(data.totalWeight)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

type BatsmanOrBowlerPercentDistributionData =
  | PercentsDistributionData
  | BatsmanPercentsDistributionData
  | BowlerPercentsDistributionData;

export const percentsDistributionDataComponent = (
  data: BatsmanOrBowlerPercentDistributionData
) => {
  const headers: string[] = [
    "Phase",
    "0",
    "1",
    "2",
    "3",
    "4",
    "6",
    "Balls",
    "Weight",
  ];

  const tableConfig = [
    {
      title: "Global",
      totalActualProperty: "totalActualByPhase",
      totalBallsProperty: "totalBallsByPhase",
      totalWeightProperty: "totalWeightByPhase",
      condition: () => true,
    },
    {
      title: "Against Pace",
      totalActualProperty: "totalActualByPhasePace",
      totalBallsProperty: "totalBallsByPhasePace",
      totalWeightProperty: "totalWeightByPhasePace",
      condition: (data) => data instanceof BatsmanPercentsDistributionData,
    },
    {
      title: "Against Spin",
      totalActualProperty: "totalActualByPhaseSpin",
      totalBallsProperty: "totalBallsByPhaseSpin",
      totalWeightProperty: "totalWeightByPhaseSpin",
      condition: (data) => data instanceof BatsmanPercentsDistributionData,
    },
    {
      title: "Against Left Handers",
      totalActualProperty: "totalActualByPhaseLeftHanders",
      totalBallsProperty: "totalBallsByPhaseLeftHanders",
      totalWeightProperty: "totalWeightByPhaseLeftHanders",
      condition: (data) => data instanceof BowlerPercentsDistributionData,
    },
    {
      title: "Against Right Handers",
      totalActualProperty: "totalActualByPhaseRightHanders",
      totalBallsProperty: "totalBallsByPhaseRightHanders",
      totalWeightProperty: "totalWeightByPhaseRightHanders",
      condition: (data) => data instanceof BowlerPercentsDistributionData,
    },
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={6} align="center">
            Outcome %
          </TableCell>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableConfig.map((config) => (
          <>
            {config.condition(data) && (
              <>
                <TableRow>
                  <TableCell colSpan={headers.length}>{config.title}</TableCell>
                </TableRow>
                {Object.keys(PushBracket).map((value: string) => {
                  const pushBracket = PushBracket[value];

                  return percentDistributionTableRow(
                    config.title,
                    data[config.totalActualProperty],
                    data[config.totalBallsProperty],
                    data[config.totalWeightProperty],
                    pushBracket
                  );
                })}
              </>
            )}
          </>
        ))}
      </TableBody>
    </Table>
  );
};
const percentDistributionTableRow = (
  name: string,
  data: Map<PushBracket, Map<BowlOutcome, number>>,
  ballsByPhase: Map<PushBracket, number>,
  weightByPhase: Map<PushBracket, number>,
  pushBracket: PushBracket
) => {
  return (
    <TableRow key={`data-row-${name}-${pushBracket}`}>
      <TableCell>{humanReadablePushBrackets[pushBracket]}</TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.DOT))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.ONE))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.TWO))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.THREE))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.FOUR))}
      </TableCell>
      <TableCell>
        {format(data.get(pushBracket).get(BowlOutcome.SIX))}
      </TableCell>
      <TableCell>{format(ballsByPhase.get(pushBracket))}</TableCell>
      <TableCell>{format(weightByPhase.get(pushBracket))}</TableCell>
    </TableRow>
  );
};

export const standardSingleBiasDataComponent = (
  data: GroundBiasByBowlerTypeData | StrikeRateBiasData | WicketBiasData
) => {
  const headers: string[] = ["Actual", "Expected", "Bias", "Balls"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={`data-row-global`}>
          <TableCell>{format(data.actual)}</TableCell>
          <TableCell>{format(data.expected)}</TableCell>
          <TableCell>{format(data.actual / data.expected)}</TableCell>
          <TableCell>{format(data.occurrences)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const batsmanWidesFormDataComponent = (data: BatsmanWidesFormData) => {
  const headers: string[] = ["Actual Wides", "Expected Wides"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{format(data.actual)}</TableCell>
          <TableCell>{format(data.expected)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const unusedModuleDataComponent = (
  <div className="italic padded centered">This module is unused</div>
);

export const ordersComponent = (data: AveragePositionData) => {
  return (
    <div>
      <div>Orders:</div>
      <div>{(data as AveragePositionData).orders.join(", ")}</div>
    </div>
  );
};

export const bowlerSpecialityDataComponent = (
  data: BowlerSpecialityPlayerFormData
) => {
  const headers: string[] = [
    ...Object.values(bowlerSpecialityNames),
    "Matches",
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {Object.keys(BowlerSpeciality).map((key) => {
            const bowlerSpeciality = BowlerSpeciality[key];
            return (
              <TableCell>
                {data.totalOccurrences.get(bowlerSpeciality)}
              </TableCell>
            );
          })}
          <TableCell>{data.matches}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const standardSingleBiasDataComponentWithWeight = (
  data: BowlingAgainstBatTypeBiasData
) => {
  const headers: string[] = ["Actual", "Expected", "Bias", "Balls", "Weight"];

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={`header-${index}`}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={`data-row-global`}>
          <TableCell>{format(data.actual)}</TableCell>
          <TableCell>{format(data.expected)}</TableCell>
          <TableCell>{format(data.actual / data.expected)}</TableCell>
          <TableCell>{format(data.occurrences)}</TableCell>
          <TableCell>{format(data.weight)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
