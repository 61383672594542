import { Component } from "react";
import {
  PlayerStats,
  PlayerStatsWrapper,
} from "../../types/stats/player-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { UserPreferences } from "../../types/preferences/preferences";
import { PercentAdjustModuleType } from "../../types/simulator/modules/percent-adjust-modules";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import PlayerPercentsDistributionModal from "../stats-editing-components/player-percents-distribution-modal";
import { batchUpdate } from "./squad-page-utils";

interface Props {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  title: string;
  strikeRateProperty: string;
  wicketPercentProperty: string;
  extrasProperty: string;
  confidenceProperty: string;
  percentDistributionProperty: string;
  percentDistributionPropertyName: string;
  percentDistributionConfidence: number;
  globalDistributionProperty: string;
  globalDistributionConfidence: number;
  userPreferences: UserPreferences;
  classes: string;
  percentAdjustModuleType: PercentAdjustModuleType;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
}

interface State {
  percentDistributionModalOpen: boolean;
}

export class PlayerStrikeRateAndWicketPercentStats extends Component<
  Props,
  State
> {
  STATS_TO_STEAL: string[] = [
    this.props.strikeRateProperty,
    this.props.wicketPercentProperty,
    this.props.confidenceProperty,
    this.props.extrasProperty,
    this.props.percentDistributionProperty,
  ].filter((value) => value !== null);

  static defaultProps = {
    extrasProperty: null,
    percentDistributionProperty: null,
    percentDistributionPropertyName: null,
    percentDistributionConfidence: null,
    userPreferences: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      percentDistributionModalOpen: false,
    };
  }

  private onClickHandler(updates: string[]): void {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      updates,
      this.props.onUpdate
    );
  }

  private callUpdateFunction(propertyName: string, newValue: any) {
    this.props.onUpdate(
      new PlayerStatsWrapper(
        this.props.currentStats.playerStatsId,
        this.props.currentStats.createdBy,
        this.props.currentStats.createdAt,
        this.props.currentStats.name,
        {
          ...this.props.currentStats.playerStats,
          [propertyName]: newValue,
        },
        this.props.currentStats.matchType
      )
    );
  }

  public render() {
    return (
      <div className="stats-modal-section">
        <div className={`stats-modal-section-head ${this.props.classes}`}>
          {this.props.title}
        </div>
        <div>
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Strike Rate:"
            min={0}
            max={1000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.playerStats[this.props.strikeRateProperty]
            }
            onValid={(newValue) =>
              this.callUpdateFunction(this.props.strikeRateProperty, newValue)
            }
            button={
              this.props.percentDistributionProperty && (
                <TooltipIconButton
                  title={"Percent Distribution"}
                  onClick={() =>
                    this.setState({ percentDistributionModalOpen: true })
                  }
                  icon={"bar_chart"}
                />
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Wicket Percent:"
            min={0}
            max={1000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.playerStats[
                this.props.wicketPercentProperty
              ]
            }
            onValid={(newValue) =>
              this.callUpdateFunction(
                this.props.wicketPercentProperty,
                newValue
              )
            }
          />
          {this.props.extrasProperty != null && (
            <NumberSelector
              className="stats-modal-number-selector"
              textFieldClassName="stats-modal-number-selector-textfield"
              label="Extras:"
              min={0}
              max={3}
              step={0.01}
              decimalPlaces={2}
              initial={
                this.props.currentStats.playerStats[this.props.extrasProperty]
              }
              onValid={(newValue) =>
                this.callUpdateFunction(this.props.extrasProperty, newValue)
              }
            />
          )}
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Confidence:"
            min={0}
            max={1000000}
            step={1}
            initial={
              this.props.currentStats.playerStats[this.props.confidenceProperty]
            }
            onValid={(newValue) =>
              this.callUpdateFunction(this.props.confidenceProperty, newValue)
            }
          />
          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title-and-steal-button">
                <span>Compared to {this.props.comparedUserName}</span>
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "playerStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "playerStats"
                  )}
                  onClickHandler={this.onClickHandler.bind(
                    this,
                    this.STATS_TO_STEAL
                  )}
                />
              </div>
              <NumberComparison
                label="Strike Rate"
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.strikeRateProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.strikeRateProperty
                  ]
                }
              />
              <NumberComparison
                label="Wicket Percent"
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.wicketPercentProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.wicketPercentProperty
                  ]
                }
              />
              {this.props.extrasProperty != null && (
                <NumberComparison
                  label="Extras"
                  originalValue={
                    this.props.currentStats.playerStats[
                      this.props.extrasProperty
                    ]
                  }
                  comparedValue={
                    this.props.comparedStats.playerStats[
                      this.props.extrasProperty
                    ]
                  }
                />
              )}
              <NumberComparison
                label="Confidence"
                originalValue={
                  this.props.currentStats.playerStats[
                    this.props.confidenceProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.playerStats[
                    this.props.confidenceProperty
                  ]
                }
              />
            </div>
          )}
        </div>

        {this.props.percentDistributionProperty && (
          <PlayerPercentsDistributionModal
            open={this.state.percentDistributionModalOpen}
            onProceed={(stats: PlayerStats) => {
              this.setState({ percentDistributionModalOpen: false });
              this.props.onUpdate(
                new PlayerStatsWrapper(
                  this.props.currentStats.playerStatsId,
                  this.props.currentStats.createdBy,
                  this.props.currentStats.createdAt,
                  this.props.currentStats.name,
                  stats,
                  this.props.currentStats.matchType
                )
              );
            }}
            onCancel={() =>
              this.setState({ percentDistributionModalOpen: false })
            }
            initialStats={this.props.currentStats.playerStats}
            percentDistributionBiasProperty={
              this.props.percentDistributionProperty
            }
            percentDistributionBiasPropertyName={
              this.props.percentDistributionPropertyName
            }
            percentDistributionConfidence={
              this.props.percentDistributionConfidence
            }
            userPreferences={this.props.userPreferences}
            comparedStats={
              this.props.comparedStats && this.props.comparedStats.playerStats
            }
            comparedUserName={this.props.comparedUserName}
            globalDistributionProperty={this.props.globalDistributionProperty}
            globalDistributionConfidence={
              this.props.globalDistributionConfidence
            }
            percentAdjustModuleType={this.props.percentAdjustModuleType}
          />
        )}
      </div>
    );
  }
}
