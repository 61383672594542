import { ReactNode } from "react";
import {
  batsmanWidesFormDataComponent,
  bowlerExtrasFormDataComponent,
  bowlerSpecialityDataComponent,
  opponentSkillFormDataComponent,
  ordersComponent,
  percentsDistributionDataComponent,
  pushBracketAndGlobalDataComponent,
  pushBracketDataComponent,
  standardSingleBiasDataComponent,
  standardSingleBiasDataComponentWithWeight,
  unusedModuleDataComponent,
  upToBallsDataComponent,
} from "../../components/common-components/form-module-data-display-components";
import { BowlOutcome } from "../enums/bowl-outcome";
import { BowlerSpeciality } from "../enums/bowler-speciality";
import { ExtrasType } from "../enums/extras-type";
import { PushBracket } from "../enums/push-bracket";

export enum PlayerFormModuleType {
  GLOBAL_STRIKE_RATE_BIAS = "GLOBAL_STRIKE_RATE_BIAS",
  GLOBAL_WICKET_BIAS = "GLOBAL_WICKET_BIAS",
  EXTREME_PACE_STRIKE_RATE_BIAS = "EXTREME_PACE_STRIKE_RATE_BIAS",
  NORMAL_PACE_STRIKE_RATE_BIAS = "NORMAL_PACE_STRIKE_RATE_BIAS",
  INTO_SPIN_STRIKE_RATE_BIAS = "INTO_SPIN_STRIKE_RATE_BIAS",
  AWAY_SPIN_STRIKE_RATE_BIAS = "AWAY_SPIN_STRIKE_RATE_BIAS",
  WRIST_SPIN_STRIKE_RATE_BIAS = "WRIST_SPIN_STRIKE_RATE_BIAS",
  EXTREME_PACE_WICKET_BIAS = "EXTREME_PACE_WICKET_BIAS",
  NORMAL_PACE_WICKET_BIAS = "NORMAL_PACE_WICKET_BIAS",
  INTO_SPIN_WICKET_BIAS = "INTO_SPIN_WICKET_BIAS",
  AWAY_SPIN_WICKET_BIAS = "AWAY_SPIN_WICKET_BIAS",
  WRIST_SPIN_WICKET_BIAS = "WRIST_SPIN_WICKET_BIAS",
  PUSH_BRACKET_STRIKE_RATE_BIAS = "PUSH_BRACKET_STRIKE_RATE_BIAS",
  PUSH_BRACKET_WICKET_BIAS = "PUSH_BRACKET_WICKET_BIAS",
  BALL_STRIKE_RATE_BIAS = "BALL_STRIKE_RATE_BIAS",
  BALL_WICKET_BIAS = "BALL_WICKET_BIAS",
  BATSMAN_WIDES = "BATSMAN_WIDES",
  BATSMAN_PERCENTS_DISTRIBUTION = "BATSMAN_PERCENTS_DISTRIBUTION",
  BOWLER_PERCENTS_DISTRIBUTION = "BOWLER_PERCENTS_DISTRIBUTION",
  BATTING_OPPONENT_SKILL = "BATTING_OPPONENT_SKILL",
  BOWLING_STRIKE_RATE_BIAS = "BOWLING_STRIKE_RATE_BIAS",
  BOWLING_WICKET_BIAS = "BOWLING_WICKET_BIAS",
  AVERAGE_POSITION = "AVERAGE_POSITION",
  BOWLER_SPECIALITY_PHASE = "BOWLER_SPECIALITY_PHASE",
  BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS = "BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS",
  BOWLING_VS_LEFT_HANDER_WICKET_BIAS = "BOWLING_VS_LEFT_HANDER_WICKET_BIAS",
  BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS = "BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS",
  BOWLING_VS_RIGHT_HANDER_WICKET_BIAS = "BOWLING_VS_RIGHT_HANDER_WICKET_BIAS",
  BOWLING_OPPONENT_SKILL = "BOWLING_OPPONENT_SKILL",
  BOWLER_EXTRAS = "BOWLER_EXTRAS",
}

export class PlayerFormData {
  enabled: boolean;
  subType: string;
  propertiesToSerialize: string[];

  constructor(json: any) {
    this.enabled = json.enabled;
    this.subType = json.subType;
    this.propertiesToSerialize = ["enabled", "subType"];
  }
}

export class BatsmanPercentsDistributionData extends PlayerFormData {
  totalBalls: number;
  totalBallsByPhase: Map<PushBracket, number>;
  totalWeightByPhase: Map<PushBracket, number>;
  totalActualByPhase: Map<PushBracket, Map<BowlOutcome, number>>;
  totalBallsByPhaseSpin: Map<PushBracket, number>;
  totalWeightByPhaseSpin: Map<PushBracket, number>;
  totalActualByPhaseSpin: Map<PushBracket, Map<BowlOutcome, number>>;
  totalBallsByPhasePace: Map<PushBracket, number>;
  totalWeightByPhasePace: Map<PushBracket, number>;
  totalActualByPhasePace: Map<PushBracket, Map<BowlOutcome, number>>;

  constructor(json: any) {
    super(json);
    this.totalBalls = json.totalBalls;
    this.totalBallsByPhase = deserializePushBracketToNumberMap(
      json.totalBallsByPhase
    );
    this.totalWeightByPhase = deserializePushBracketToNumberMap(
      json.totalWeightByPhase
    );
    this.totalActualByPhase = deserializePushBracketToOutcomePercentsMap(
      json.totalActualByPhase
    );

    this.totalBallsByPhaseSpin = deserializePushBracketToNumberMap(
      json.totalBallsByPhaseSpin
    );
    this.totalWeightByPhaseSpin = deserializePushBracketToNumberMap(
      json.totalWeightByPhaseSpin
    );
    this.totalActualByPhaseSpin = deserializePushBracketToOutcomePercentsMap(
      json.totalActualByPhaseSpin
    );

    this.totalBallsByPhasePace = deserializePushBracketToNumberMap(
      json.totalBallsByPhasePace
    );
    this.totalWeightByPhasePace = deserializePushBracketToNumberMap(
      json.totalWeightByPhasePace
    );
    this.totalActualByPhasePace = deserializePushBracketToOutcomePercentsMap(
      json.totalActualByPhasePace
    );
  }
}

export class BowlerPercentsDistributionData extends PlayerFormData {
  totalBalls: number;
  totalBallsByPhase: Map<PushBracket, number>;
  totalWeightByPhase: Map<PushBracket, number>;
  totalActualByPhase: Map<PushBracket, Map<BowlOutcome, number>>;
  totalBallsByPhaseLeftHanders: Map<PushBracket, number>;
  totalWeightByPhaseLeftHanders: Map<PushBracket, number>;
  totalActualByPhaseLeftHanders: Map<PushBracket, Map<BowlOutcome, number>>;
  totalBallsByPhaseRightHanders: Map<PushBracket, number>;
  totalWeightByPhaseRightHanders: Map<PushBracket, number>;
  totalActualByPhaseRightHanders: Map<PushBracket, Map<BowlOutcome, number>>;

  constructor(json: any) {
    super(json);
    this.totalBalls = json.totalBalls;
    this.totalBallsByPhase = deserializePushBracketToNumberMap(
      json.totalBallsByPhase
    );
    this.totalWeightByPhase = deserializePushBracketToNumberMap(
      json.totalWeightByPhase
    );
    this.totalActualByPhase = deserializePushBracketToOutcomePercentsMap(
      json.totalActualByPhase
    );

    this.totalBallsByPhaseLeftHanders = deserializePushBracketToNumberMap(
      json.totalBallsByPhaseLeftHanders
    );
    this.totalWeightByPhaseLeftHanders = deserializePushBracketToNumberMap(
      json.totalWeightByPhaseLeftHanders
    );
    this.totalActualByPhaseLeftHanders =
      deserializePushBracketToOutcomePercentsMap(
        json.totalActualByPhaseLeftHanders
      );

    this.totalBallsByPhaseRightHanders = deserializePushBracketToNumberMap(
      json.totalBallsByPhaseRightHanders
    );
    this.totalWeightByPhaseRightHanders = deserializePushBracketToNumberMap(
      json.totalWeightByPhaseRightHanders
    );
    this.totalActualByPhaseRightHanders =
      deserializePushBracketToOutcomePercentsMap(
        json.totalActualByPhaseRightHanders
      );
  }
}

export class BatsmanWidesFormData extends PlayerFormData {
  expected: number;
  actual: number;

  constructor(json: any) {
    super(json);
    this.expected = json.totalExpectedWides;
    this.actual = json.totalActualWides;
  }
}

export class BiasByPushBracketData extends PlayerFormData {
  occurrences: number[];
  expected: number[];
  actual: number[];
  weight: number[];

  constructor(json: any) {
    super(json);
    this.occurrences = json.totalBalls;
    this.expected = json.totalExpected;
    this.actual = json.totalActual;
    this.weight = json.totalWeights;
  }
}

export class BiasByBallData extends PlayerFormData {
  occurrences: number[];
  expected: number[];
  actual: number[];
  weight: number[];

  constructor(json: any) {
    super(json);
    this.occurrences = json.totalBalls;
    this.expected = json.totalExpected;
    this.actual = json.totalActual;
    this.weight = json.totalWeights;
  }
}

export class BowlingAgainstBatTypeBiasData extends PlayerFormData {
  occurrences: number;
  expected: number;
  actual: number;
  weight: number;

  constructor(json: any) {
    super(json);
    this.occurrences = json.totalOccurrences;
    this.expected = json.totalExpected;
    this.actual = json.totalActual;
    this.weight = json.totalWeight;
  }
}

export class BowlerSpecialityPlayerFormData extends PlayerFormData {
  matchesLimit: number;
  totalOccurrences: Map<BowlerSpeciality, number>;
  matches: number;

  constructor(json: any) {
    super(json);
    this.matchesLimit = json.matchesLimit;
    this.matches = json.matches;
    this.totalOccurrences = deserializeBowlerSpecialityToNumberMap(
      json.totalOccurrences
    );
    this.propertiesToSerialize = [
      ...this.propertiesToSerialize,
      "matchesLimit",
    ];
  }
}

export class AveragePositionData extends PlayerFormData {
  orders: number[];

  constructor(json: any) {
    super(json);
    this.orders = json.orders;
  }
}

export class BowlingBiasData extends PlayerFormData {
  globalWeight: number;
  globalOccurrences: number;
  globalExpected: number;
  globalActual: number;
  totalWeights: number[];
  totalBalls: number[];
  totalExpected: number[];
  totalActual: number[];

  constructor(json: any) {
    super(json);
    this.globalWeight = json.globalWeight;
    this.globalOccurrences = json.globalOccurrences;
    this.globalExpected = json.globalExpected;
    this.globalActual = json.globalActual;
    this.totalWeights = json.totalWeights;
    this.totalBalls = json.totalOccurrences;
    this.totalExpected = json.totalExpected;
    this.totalActual = json.totalActual;
  }
}

export class StrikeRateBiasData extends PlayerFormData {
  occurrences: number;
  expected: number;
  actual: number;

  constructor(json: any) {
    super(json);
    this.occurrences = json.totalBalls;
    this.expected = json.totalExpectedRuns;
    this.actual = json.totalActualRuns;
  }
}

export class WicketBiasData extends PlayerFormData {
  occurrences: number;
  expected: number;
  actual: number;

  constructor(json: any) {
    super(json);
    this.occurrences = json.totalBalls;
    this.expected = json.totalExpectedWickets;
    this.actual = json.totalActualWickets;
  }
}

export class OpponentSkillFormData extends PlayerFormData {
  totalWeight: number;
  totalGroundSRBias: number;
  totalGroundWicketBias: number;
  totalOpponentSRBias: number;
  totalOpponentWicketBias: number;

  constructor(json: any) {
    super(json);
    this.totalWeight = json.totalWeight;
    this.totalGroundSRBias = json.totalGroundSRBias;
    this.totalGroundWicketBias = json.totalGroundWicketBias;
    this.totalOpponentSRBias = json.totalOpponentSRBias;
    this.totalOpponentWicketBias = json.totalOpponentWicketBias;
  }
}

export class BowlerExtrasFormData extends PlayerFormData {
  totalExpectedExtras: number;
  totalActualExtras: number;
  totalExpectedExtrasByPush: Map<PushBracket, number>;
  totalActualExtrasByPush: Map<PushBracket, number>;
  totalExpectedExtrasByType: Map<ExtrasType, number>;
  totalActualExtrasByType: Map<ExtrasType, number>;

  constructor(json: any) {
    super(json);

    this.totalExpectedExtras = json.totalExpectedExtras;
    this.totalActualExtras = json.totalActualExtras;
    this.totalExpectedExtrasByPush = deserializePushBracketToNumberMap(
      json.totalExpectedExtrasByPush
    );
    this.totalActualExtrasByPush = deserializePushBracketToNumberMap(
      json.totalActualExtrasByPush
    );
    this.totalExpectedExtrasByType = deserializeExtrasTypeToNumberMap(
      json.totalExpectedExtrasByType
    );
    this.totalActualExtrasByType = deserializeExtrasTypeToNumberMap(
      json.totalActualExtrasByType
    );
  }
}

export const playerFormModuleToDataTypes: Record<
  PlayerFormModuleType,
  (any) => PlayerFormData
> = {
  BOWLER_SPECIALITY_PHASE: (json) => new BowlerSpecialityPlayerFormData(json),
  BOWLING_STRIKE_RATE_BIAS: (json) => new BowlingBiasData(json),
  BOWLING_WICKET_BIAS: (json) => new BowlingBiasData(json),
  GLOBAL_STRIKE_RATE_BIAS: (json) => new StrikeRateBiasData(json),
  GLOBAL_WICKET_BIAS: (json) => new WicketBiasData(json),
  EXTREME_PACE_STRIKE_RATE_BIAS: (json) => new StrikeRateBiasData(json),
  NORMAL_PACE_STRIKE_RATE_BIAS: (json) => new StrikeRateBiasData(json),
  INTO_SPIN_STRIKE_RATE_BIAS: (json) => new StrikeRateBiasData(json),
  AWAY_SPIN_STRIKE_RATE_BIAS: (json) => new StrikeRateBiasData(json),
  WRIST_SPIN_STRIKE_RATE_BIAS: (json) => new StrikeRateBiasData(json),
  EXTREME_PACE_WICKET_BIAS: (json) => new WicketBiasData(json),
  NORMAL_PACE_WICKET_BIAS: (json) => new WicketBiasData(json),
  INTO_SPIN_WICKET_BIAS: (json) => new WicketBiasData(json),
  AWAY_SPIN_WICKET_BIAS: (json) => new WicketBiasData(json),
  WRIST_SPIN_WICKET_BIAS: (json) => new WicketBiasData(json),
  AVERAGE_POSITION: (json) => new AveragePositionData(json),
  PUSH_BRACKET_STRIKE_RATE_BIAS: (json) => new BiasByPushBracketData(json),
  PUSH_BRACKET_WICKET_BIAS: (json) => new BiasByPushBracketData(json),
  BALL_WICKET_BIAS: (json) => new BiasByBallData(json),
  BALL_STRIKE_RATE_BIAS: (json) => new BiasByBallData(json),
  BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS: (json) =>
    new BowlingAgainstBatTypeBiasData(json),
  BOWLING_VS_LEFT_HANDER_WICKET_BIAS: (json) =>
    new BowlingAgainstBatTypeBiasData(json),
  BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS: (json) =>
    new BowlingAgainstBatTypeBiasData(json),
  BOWLING_VS_RIGHT_HANDER_WICKET_BIAS: (json) =>
    new BowlingAgainstBatTypeBiasData(json),
  BOWLING_OPPONENT_SKILL: (json) => new OpponentSkillFormData(json),
  BATTING_OPPONENT_SKILL: (json) => new OpponentSkillFormData(json),
  BOWLER_EXTRAS: (json) => new BowlerExtrasFormData(json),
  BATSMAN_WIDES: (json) => new BatsmanWidesFormData(json),
  BATSMAN_PERCENTS_DISTRIBUTION: (json) =>
    new BatsmanPercentsDistributionData(json),
  BOWLER_PERCENTS_DISTRIBUTION: (json) =>
    new BowlerPercentsDistributionData(json),
};

export const playerFormDataDisplayComponents = (
  type: PlayerFormModuleType,
  data: PlayerFormData
) => {
  switch (type) {
    case PlayerFormModuleType.AVERAGE_POSITION:
      return ordersComponent(data as AveragePositionData);
    case PlayerFormModuleType.BOWLER_SPECIALITY_PHASE:
      return bowlerSpecialityDataComponent(
        data as BowlerSpecialityPlayerFormData
      );
    case PlayerFormModuleType.BOWLING_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.BOWLING_WICKET_BIAS:
      return pushBracketAndGlobalDataComponent(data as BowlingBiasData);
    case PlayerFormModuleType.GLOBAL_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.EXTREME_PACE_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.NORMAL_PACE_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.INTO_SPIN_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.AWAY_SPIN_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.WRIST_SPIN_STRIKE_RATE_BIAS:
      return standardSingleBiasDataComponent(data as StrikeRateBiasData);
    case PlayerFormModuleType.GLOBAL_WICKET_BIAS:
    case PlayerFormModuleType.EXTREME_PACE_WICKET_BIAS:
    case PlayerFormModuleType.NORMAL_PACE_WICKET_BIAS:
    case PlayerFormModuleType.INTO_SPIN_WICKET_BIAS:
    case PlayerFormModuleType.AWAY_SPIN_WICKET_BIAS:
    case PlayerFormModuleType.WRIST_SPIN_WICKET_BIAS:
      return standardSingleBiasDataComponent(data as WicketBiasData);
    case PlayerFormModuleType.BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.BOWLING_VS_LEFT_HANDER_WICKET_BIAS:
    case PlayerFormModuleType.BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.BOWLING_VS_RIGHT_HANDER_WICKET_BIAS:
      return standardSingleBiasDataComponentWithWeight(
        data as BowlingAgainstBatTypeBiasData
      );
    case PlayerFormModuleType.PUSH_BRACKET_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.PUSH_BRACKET_WICKET_BIAS:
      return pushBracketDataComponent(data as BiasByPushBracketData);
    case PlayerFormModuleType.BALL_STRIKE_RATE_BIAS:
    case PlayerFormModuleType.BALL_WICKET_BIAS:
      return upToBallsDataComponent(data as BiasByBallData);
    case PlayerFormModuleType.BOWLING_OPPONENT_SKILL:
    case PlayerFormModuleType.BATTING_OPPONENT_SKILL:
      return opponentSkillFormDataComponent(data as OpponentSkillFormData);
    case PlayerFormModuleType.BOWLER_EXTRAS:
      return bowlerExtrasFormDataComponent(data as BowlerExtrasFormData);
    case PlayerFormModuleType.BATSMAN_WIDES:
      return batsmanWidesFormDataComponent(data as BatsmanWidesFormData);
    case PlayerFormModuleType.BATSMAN_PERCENTS_DISTRIBUTION:
      return percentsDistributionDataComponent(
        data as BatsmanPercentsDistributionData
      );
    case PlayerFormModuleType.BOWLER_PERCENTS_DISTRIBUTION:
      return percentsDistributionDataComponent(
        data as BowlerPercentsDistributionData
      );
    default:
      return unusedModuleDataComponent;
  }
};

export const playerFormModuleNames: Record<PlayerFormModuleType, string> = {
  GLOBAL_STRIKE_RATE_BIAS: "Batting - Global Strike Rate Bias",
  GLOBAL_WICKET_BIAS: "Batting - Global Wicket Bias",
  EXTREME_PACE_STRIKE_RATE_BIAS: "Batting - Extreme Pace Strike Rate Bias",
  NORMAL_PACE_STRIKE_RATE_BIAS: "Batting - Normal Pace Strike Rate Bias",
  INTO_SPIN_STRIKE_RATE_BIAS: "Batting - Into Spin Strike Rate Bias",
  AWAY_SPIN_STRIKE_RATE_BIAS: "Batting - Away Spin Strike Rate Bias",
  WRIST_SPIN_STRIKE_RATE_BIAS: "Batting - Wrist Spin Strike Rate Bias",
  EXTREME_PACE_WICKET_BIAS: "Batting - Extreme Pace Wicket Bias",
  NORMAL_PACE_WICKET_BIAS: "Batting - Normal Pace Wicket Bias",
  INTO_SPIN_WICKET_BIAS: "Batting - Into Spin Wicket Bias",
  AWAY_SPIN_WICKET_BIAS: "Batting - Away Spin Wicket Bias",
  WRIST_SPIN_WICKET_BIAS: "Batting - Wrist Spin Wicket Bias",
  PUSH_BRACKET_STRIKE_RATE_BIAS: "Batting - Strike Rate Bias by Push",
  PUSH_BRACKET_WICKET_BIAS: "Batting - Wicket Bias by Push",
  BALL_STRIKE_RATE_BIAS: "Batting - Strike Rate Bias by Balls Faced",
  BALL_WICKET_BIAS: "Batting - Wicket Bias by Balls Faced",
  BATSMAN_WIDES: "Batting - Wides",
  BATSMAN_PERCENTS_DISTRIBUTION: "Batting - Percent Distribution",
  BATTING_OPPONENT_SKILL: "Batting - Opponent Skill",
  AVERAGE_POSITION: "Batting - Average Position",
  BOWLING_STRIKE_RATE_BIAS: "Bowling - Strike Rate Bias",
  BOWLING_WICKET_BIAS: "Bowling - Wicket Bias",
  BOWLER_SPECIALITY_PHASE: "Bowling - Speciality Phase",
  BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS:
    "Bowling - Strike Rate Bias against Left-handed Batsmen",
  BOWLING_VS_LEFT_HANDER_WICKET_BIAS:
    "Bowling - Wicket Bias against Left-handed Batsmen",
  BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS:
    "Bowling - Strike Rate Bias against Right-handed Batsmen",
  BOWLING_VS_RIGHT_HANDER_WICKET_BIAS:
    "Bowling - Wicket Bias against Right-handed Batsmen",
  BOWLING_OPPONENT_SKILL: "Bowling - Opponent Skill",
  BOWLER_EXTRAS: "Bowling - Extras",
  BOWLER_PERCENTS_DISTRIBUTION: "Bowling - Percent Distribution",
};

export const playerFormModuleTooltips: Record<PlayerFormModuleType, string> = {
  BOWLER_SPECIALITY_PHASE: "Bowler Speciality Phase Settings",
  GLOBAL_STRIKE_RATE_BIAS: "Global Strike Rate Bias Settings",
  GLOBAL_WICKET_BIAS: "Global Wicket Bias Settings",
  EXTREME_PACE_STRIKE_RATE_BIAS: "Extreme Pace Strike Rate Bias Settings",
  NORMAL_PACE_STRIKE_RATE_BIAS: "Normal Pace Strike Rate Bias Settings",
  INTO_SPIN_STRIKE_RATE_BIAS: "Into Spin Strike Rate Bias Settings",
  AWAY_SPIN_STRIKE_RATE_BIAS: "Away Spin Strike Rate Bias Settings",
  WRIST_SPIN_STRIKE_RATE_BIAS: "Wrist Spin Strike Rate Bias Settings",
  EXTREME_PACE_WICKET_BIAS: "Extreme Pace Wicket Bias Settings",
  NORMAL_PACE_WICKET_BIAS: "Normal Pace Wicket Bias Settings",
  INTO_SPIN_WICKET_BIAS: "Into Spin Wicket Bias Settings",
  AWAY_SPIN_WICKET_BIAS: "Away Spin Wicket Bias Settings",
  WRIST_SPIN_WICKET_BIAS: "Wrist Spin Wicket Bias Settings",
  BOWLING_STRIKE_RATE_BIAS: "Strike Rate Bias Settings",
  BOWLING_WICKET_BIAS: "Wicket Bias Settings",
  AVERAGE_POSITION: "Average Position Settings",
  PUSH_BRACKET_STRIKE_RATE_BIAS: "Batting Strike Rate Bias by Push Settings",
  PUSH_BRACKET_WICKET_BIAS: "Batting Wicket Bias by Push Settings",
  BALL_WICKET_BIAS: "Batting Wicket Bias by Balls Faced Settings",
  BALL_STRIKE_RATE_BIAS: "Batting Strike Rate Bias by Balls Faced Settings",
  BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS:
    "Bowling Strike Rate Bias against Left-handed Batsmen Settings",
  BOWLING_VS_LEFT_HANDER_WICKET_BIAS:
    "Bowling Wicket Bias against Left-handed Batsmen Settings",
  BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS:
    "Bowling Strike Rate Bias against Right-handed Batsmen Settings",
  BOWLING_VS_RIGHT_HANDER_WICKET_BIAS:
    "Bowling Wicket Bias against Right-handed Batsmen Settings",
  BOWLING_OPPONENT_SKILL: "Bowling Opponent Settings",
  BATTING_OPPONENT_SKILL: "Batting Opponent Settings",
  BOWLER_EXTRAS: "Bowler Extras Settings",
  BATSMAN_WIDES: "Batsman Wides Settings",
  BATSMAN_PERCENTS_DISTRIBUTION: "Batsman Percent Distribution Settings",
  BOWLER_PERCENTS_DISTRIBUTION: "Bowler Percent Distribution Settings",
};

export const playerFormModuleIsBattingModule: Record<
  PlayerFormModuleType,
  boolean
> = {
  BOWLER_SPECIALITY_PHASE: false,
  GLOBAL_STRIKE_RATE_BIAS: true,
  GLOBAL_WICKET_BIAS: true,
  EXTREME_PACE_STRIKE_RATE_BIAS: true,
  NORMAL_PACE_STRIKE_RATE_BIAS: true,
  INTO_SPIN_STRIKE_RATE_BIAS: true,
  AWAY_SPIN_STRIKE_RATE_BIAS: true,
  WRIST_SPIN_STRIKE_RATE_BIAS: true,
  EXTREME_PACE_WICKET_BIAS: true,
  NORMAL_PACE_WICKET_BIAS: true,
  INTO_SPIN_WICKET_BIAS: true,
  AWAY_SPIN_WICKET_BIAS: true,
  WRIST_SPIN_WICKET_BIAS: true,
  BOWLING_STRIKE_RATE_BIAS: false,
  BOWLING_WICKET_BIAS: false,
  AVERAGE_POSITION: true,
  PUSH_BRACKET_STRIKE_RATE_BIAS: true,
  PUSH_BRACKET_WICKET_BIAS: true,
  BALL_WICKET_BIAS: true,
  BALL_STRIKE_RATE_BIAS: true,
  BATTING_OPPONENT_SKILL: true,
  BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS: false,
  BOWLING_VS_LEFT_HANDER_WICKET_BIAS: false,
  BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS: false,
  BOWLING_VS_RIGHT_HANDER_WICKET_BIAS: false,
  BOWLING_OPPONENT_SKILL: false,
  BOWLER_EXTRAS: false,
  BATSMAN_WIDES: true,
  BATSMAN_PERCENTS_DISTRIBUTION: true,
  BOWLER_PERCENTS_DISTRIBUTION: false,
};

export interface FormModuleDataProperty {
  humanReadableName: string;
  description: ReactNode;
  propertyName: string;
  type: "number";
  constraints: any;
}

export const playerFormDataProperties: Record<
  PlayerFormModuleType,
  FormModuleDataProperty[]
> = {
  BOWLER_SPECIALITY_PHASE: [],
  BOWLING_STRIKE_RATE_BIAS: [],
  BOWLING_WICKET_BIAS: [],
  GLOBAL_STRIKE_RATE_BIAS: [],
  GLOBAL_WICKET_BIAS: [],
  EXTREME_PACE_STRIKE_RATE_BIAS: [],
  NORMAL_PACE_STRIKE_RATE_BIAS: [],
  INTO_SPIN_STRIKE_RATE_BIAS: [],
  AWAY_SPIN_STRIKE_RATE_BIAS: [],
  WRIST_SPIN_STRIKE_RATE_BIAS: [],
  EXTREME_PACE_WICKET_BIAS: [],
  NORMAL_PACE_WICKET_BIAS: [],
  INTO_SPIN_WICKET_BIAS: [],
  AWAY_SPIN_WICKET_BIAS: [],
  WRIST_SPIN_WICKET_BIAS: [],
  AVERAGE_POSITION: [],
  PUSH_BRACKET_STRIKE_RATE_BIAS: [],
  PUSH_BRACKET_WICKET_BIAS: [],
  BALL_WICKET_BIAS: [],
  BALL_STRIKE_RATE_BIAS: [],
  BOWLING_VS_LEFT_HANDER_STRIKE_RATE_BIAS: [],
  BOWLING_VS_LEFT_HANDER_WICKET_BIAS: [],
  BOWLING_VS_RIGHT_HANDER_STRIKE_RATE_BIAS: [],
  BOWLING_VS_RIGHT_HANDER_WICKET_BIAS: [],
  BOWLING_OPPONENT_SKILL: [],
  BATTING_OPPONENT_SKILL: [],
  BOWLER_EXTRAS: [],
  BATSMAN_WIDES: [],
  BATSMAN_PERCENTS_DISTRIBUTION: [],
  BOWLER_PERCENTS_DISTRIBUTION: [],
};

export function deserializePlayerFormModules(
  json: any
): Map<PlayerFormModuleType, PlayerFormData> {
  const playerFormModules: Map<PlayerFormModuleType, PlayerFormData> =
    new Map();
  Object.keys(PlayerFormModuleType).forEach((moduleType) => {
    playerFormModules.set(
      PlayerFormModuleType[moduleType],
      playerFormModuleToDataTypes[PlayerFormModuleType[moduleType]](
        json[moduleType]
      )
    );
  });
  return playerFormModules;
}

export function serializePlayerFormModules(
  playerFormModules: Map<PlayerFormModuleType, PlayerFormData>
): any {
  const result: any = {};
  playerFormModules.forEach((data, moduleType) => {
    const serializedModule = {};
    data.propertiesToSerialize.map(
      (property) => (serializedModule[property] = data[property])
    );
    result[moduleType] = serializedModule;
  });
  return result;
}

function deserializeBowlerSpecialityToNumberMap(
  json: any
): Map<BowlerSpeciality, number> {
  const map: Map<BowlerSpeciality, number> = new Map();
  Object.keys(json).forEach((key) => {
    const speciality: BowlerSpeciality = BowlerSpeciality[key];
    map.set(speciality, json[key]);
  });
  return map;
}

const deserializePushBracketToNumberMap = (json: any) => {
  const map: Map<PushBracket, number> = new Map();
  Object.keys(PushBracket).forEach((pushBracket) => {
    map.set(PushBracket[pushBracket], json[pushBracket]);
  });

  return map;
};

const deserializePushBracketToOutcomePercentsMap = (json: any) => {
  const map: Map<PushBracket, Map<BowlOutcome, number>> = new Map();
  Object.keys(PushBracket).forEach((pushBracket) => {
    map.set(
      PushBracket[pushBracket],
      deserializeBowlOutcomeToNumberMap(json[pushBracket])
    );
  });

  return map;
};

const deserializeBowlOutcomeToNumberMap = (json: any) => {
  const map: Map<BowlOutcome, number> = new Map();
  Object.keys(BowlOutcome).forEach((outcome) => {
    map.set(BowlOutcome[outcome], json[outcome]);
  });

  return map;
};

const deserializeExtrasTypeToNumberMap = (json: any) => {
  const map: Map<ExtrasType, number> = new Map();
  Object.keys(ExtrasType).forEach((extrasType) => {
    map.set(ExtrasType[extrasType], json[extrasType]);
  });

  return map;
};
